<template>
  <v-data-table
      :headers="headers"
      :items="units"
      sort-by="calories"
      class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
          flat
      >
        <v-toolbar-title>Varsity Unit List</v-toolbar-title>
        <v-divider
            class="mx-4"
            inset
            vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog
            v-model="dialog"
            max-width="800px"
            persistent
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
            >
              <v-icon color="blue">mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                      cols="12"
                  >
                    <v-text-field
                        v-model="editedItem.name"
                        label="Unit name"
                    ></v-text-field>
                  </v-col>
                  <div class="col-md-6"
                  >
                    <v-menu
                        v-model="form_fill_up_start"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="editedItem.form_fill_up_start"
                            label="Form fill up start date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="editedItem.form_fill_up_start"
                          @input="form_fill_up_start = false"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                  <div class="col-md-6"
                  >
                    <v-menu
                        v-model="form_fill_up_end"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="editedItem.form_fill_up_end"
                            label="Form fill up end date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="editedItem.form_fill_up_end"
                          @input="form_fill_up_end = false"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                  <div class="col-md-6"
                  >
                    <v-menu
                        v-model="exam_start"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="editedItem.exam_start"
                            label="Exam start date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="editedItem.exam_start"
                          @input="exam_start = false"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                  <div class="col-md-6"
                  >
                    <v-menu
                        v-model="interview_date"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="editedItem.interview_date"
                            label="Interview date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="editedItem.interview_date"
                          @input="interview_date = false"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                  <div class="col-md-6"
                  >
                    <v-menu
                        v-model="admit_card_start"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="editedItem.admit_card_start"
                            label="Admit card start date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="editedItem.admit_card_start"
                          @input="admit_card_start = false"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                  <div class="col-md-6"
                  >
                    <v-menu
                        v-model="admit_card_end"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="editedItem.admit_card_end"
                            label="Admit card end date"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="editedItem.admit_card_end"
                          @input="admit_card_end = false"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                  <div class="col-12">
                    <v-text-field label="Application link" v-model="editedItem.application_link"></v-text-field>
                  </div>
                  <div class="col-12">
                    <v-text-field label="Admit card link" v-model="editedItem.admit_card_link"></v-text-field>
                  </div>
                  <div class="col-12">
                    <v-text-field label="Seat plan link" v-model="editedItem.seat_plan_link"></v-text-field>
                  </div>
                  <div class="col-12">
                    <v-text-field label="Result link" v-model="editedItem.result_link"></v-text-field>
                  </div>
                  <div class="col-md-6">
                    <v-text-field label="Application Fee" v-model="editedItem.fee"></v-text-field>
                  </div>
                  <div class="col-md-6">
                    <v-text-field label="Application Charge" v-model="editedItem.charge"></v-text-field>
                  </div>
                  <div class="col-12">
                    <tiptap-vuetify
                        v-model="editedItem.details"
                        :extensions="extensions"
                        placeholder="Enter units details"
                    >
                    </tiptap-vuetify>
                  </div>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                  color="blue darken-1"
                  text
                  @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.name="{ item }">
      <router-link :to="{name: 'Eligibility',params:{unitId:item.id}}">{{ item.name }}</router-link>
    </template>
    <template v-slot:item.sms_alert="{ item }">
      <v-switch
          v-model="item.sms_alert"
          @click="toggleSmsAlert(item)"
      ></v-switch>
    </template>
    <template v-slot:item.form_fill_up="{ item }">
      <v-switch
          v-model="item.form_fill_up"
          @click="toggleFormFillUp(item)"
      ></v-switch>
    </template>
    <template v-slot:item.photo="{ item }">
      <v-img max-width="100" :src="item.photo"></v-img>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
          small
          class="mr-2"
          @click="editItem(item)"
          color="blue"
      >
        mdi-pencil
      </v-icon>
      <v-icon
          small
          @click="deleteItem(item)"
          color="red"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn
          color="primary"
          @click="initialize"
      >
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>
<script>
import axios from "axios";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History
} from 'tiptap-vuetify'

export default {
  components: {
    TiptapVuetify,
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    photo: null,
    form_fill_up_start: false,
    form_fill_up_end: false,
    exam_start: false,
    interview_date: false,
    admit_card_start: false,
    admit_card_end: false,
    headers: [
      {
        text: 'Name',
        align: 'start',
        value: 'name',
      },
      {
        text: 'SMS Alert',
        value: 'sms_alert'
      },
      {
        text: 'Form Fill-up',
        value: 'form_fill_up',
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false
      },
    ],
    units: [],
    editedIndex: -1,
    editedItem: {
      name: '',
      details: '',
      form_fill_up_start: '',
      form_fill_up_end: '',
      exam_start: '',
      interview_date: '',
      admit_card_link: '',
      admit_card_start: '',
      admit_card_end: '',
      seat_plan_link: '',
      result_link: '',
      ssc_passing_year: '',
      ssc_gpa_without_optional: '',
      ssc_gpa_with_optional: '',
      ssc_group: [],
      hsc_passing_year: '',
      hsc_gpa_without_optional: '',
      hsc_gpa_with_optional: '',
      hsc_group: [],
      fee: '',
      charge: '',
      application_link: '',
    },
    defaultItem: {
      name: '',
      details: '',
      form_fill_up_start: '',
      form_fill_up_end: '',
      exam_start: '',
      interview_date: '',
      admit_card_link: '',
      admit_card_start: '',
      admit_card_end: '',
      seat_plan_link: '',
      result_link: '',
      ssc_passing_year: '',
      ssc_gpa_without_optional: '',
      ssc_gpa_with_optional: '',
      ssc_group: [],
      hsc_passing_year: '',
      hsc_gpa_without_optional: '',
      hsc_gpa_with_optional: '',
      hsc_group: [],
      fee: '',
      charge: '',
      application_link: '',
    },
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [Heading, {
        options: {
          levels: [1, 2, 3]
        }
      }],
      Bold,
      Link,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak
    ],
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
    form() {
      let formData = new FormData()
      if (this.editedIndex > -1) {
        formData.append('_method', 'PUT')
      } else {
        formData.append('varsity_id', this.$route.params.varsityId)
      }
      formData.append('name', this.editedItem.name)
      formData.append('details', this.editedItem.details)
      formData.append('form_fill_up_start', this.editedItem.form_fill_up_start)
      formData.append('form_fill_up_end', this.editedItem.form_fill_up_end)
      formData.append('exam_start', this.editedItem.exam_start)
      formData.append('interview_date', this.editedItem.interview_date)
      formData.append('admit_card_link', this.editedItem.admit_card_link)
      formData.append('admit_card_start', this.editedItem.admit_card_start)
      formData.append('admit_card_end', this.editedItem.admit_card_end)
      formData.append('seat_plan_link', this.editedItem.seat_plan_link)
      formData.append('result_link', this.editedItem.result_link)
      formData.append('ssc_passing_year', this.editedItem.ssc_passing_year)
      formData.append('ssc_gpa_without_optional', this.editedItem.ssc_gpa_without_optional)
      formData.append('ssc_gpa_with_optional', this.editedItem.ssc_gpa_with_optional)
      formData.append('ssc_group', this.editedItem.ssc_group)
      formData.append('hsc_passing_year', this.editedItem.hsc_passing_year)
      formData.append('hsc_gpa_without_optional', this.editedItem.hsc_gpa_without_optional)
      formData.append('hsc_gpa_with_optional', this.editedItem.hsc_gpa_with_optional)
      formData.append('hsc_group', this.editedItem.hsc_group)
      formData.append('fee', this.editedItem.fee)
      formData.append('charge', this.editedItem.charge)
      formData.append('application_link', this.editedItem.application_link)
      return formData
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  created() {
    this.initialize()
  },

  methods: {
    initialize() {
      const url = 'admin/units?id=' + this.$route.params.varsityId
      axios.get(url).then((response) => {
        this.units = response.data.units
      }).catch(() => {

      })
    },
    editItem(item) {
      this.editedIndex = this.units.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editedItem.ssc_group = item.ssc_group ? item.ssc_group === 'null' ? [] : item.ssc_group.split(',') : []
      this.editedItem.hsc_group = item.hsc_group ? item.hsc_group === 'null' ? [] : item.hsc_group.split(',') : []
      this.editedItem.form_fill_up_start = item.form_fill_up_start === '0000-00-00 00:00:00' ? '' : item.form_fill_up_start
      this.editedItem.form_fill_up_end = item.form_fill_up_end === '0000-00-00 00:00:00' ? '' : item.form_fill_up_end
      this.editedItem.exam_start = item.exam_start === '0000-00-00 00:00:00' ? '' : item.exam_start
      this.editedItem.interview_date = item.interview_date === '0000-00-00 00:00:00' ? '' : item.interview_date
      this.editedItem.admit_card_start = item.admit_card_start === '0000-00-00 00:00:00' ? '' : item.admit_card_start
      this.editedItem.admit_card_end = item.admit_card_end === '0000-00-00 00:00:00' ? '' : item.admit_card_end
      this.dialog = true
    },
    deleteItem(item) {
      this.editedIndex = this.units.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    toggleSmsAlert(item) {
      this.editedIndex = this.units.indexOf(item)
      this.editedItem = Object.assign({}, item)
      const url = 'admin/units/' + this.editedItem.id
      let editedIndex = this.editedIndex
      let form = {
        _method: 'PUT',
        sms_alert: item.sms_alert
      }
      axios.post(url, form).then((response) => {
        Object.assign(this.units[editedIndex], response.data)
      })
    },
    toggleFormFillUp(item) {
      this.editedIndex = this.units.indexOf(item)
      this.editedItem = Object.assign({}, item)
      const url = 'admin/units/' + this.editedItem.id
      let editedIndex = this.editedIndex
      let form = {
        _method: 'PUT',
        form_fill_up: item.form_fill_up
      }
      axios.post(url, form).then((response) => {
        Object.assign(this.units[editedIndex], response.data)
      })
    },
    deleteItemConfirm() {
      const url = 'admin/units/' + this.editedItem.id
      let editedIndex = this.editedIndex
      axios.delete(url).then(() => {
        this.units.splice(editedIndex, 1)
        this.closeDelete()
      })
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        const url = 'admin/units/' + this.editedItem.id
        let editedIndex = this.editedIndex
        axios.post(url, this.form).then((response) => {
          Object.assign(this.units[editedIndex], response.data)
        })
      } else {
        const url = 'admin/units'
        axios.post(url, this.form).then((response) => {
          this.units.push(response.data)
        })
      }
      this.close()
    },
  },
}
</script>
